  .about {
    padding: 4rem;
    background: #fff;
    flex-flow: column;
    height: 85vh;
    height: max-content;
  }

  h1 {
    text-align: left;
    color: #000;
    font-size: 42px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  h2 {
    text-align: left;
    color: #000;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  p {
    font-size: 24px;
    color: #000;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .about__container {
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 30px 0px 0px 0;
    column-gap: 100px;
    margin-bottom: 3%;
  }
  .skills__container {
    position: relative;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    column-gap: 15px;
    row-gap: 5px;
    margin-bottom: 1%;
  }

  .portrait__item {
    margin-bottom: 24px;
  }

  .portrait__pic-wrap {
    position: absolute;
    width: 20px;
    overflow: hidden;
  }
 
  .portrait__img {
    height: 30vh;
    overflow: hidden;
    border-radius: 280px;
  }

  .badge{
    background-color:#0dcaf0;
    color:#fff;
    display:inline-block;
    padding-left:20px;
    padding-right:20px;
    padding-bottom: 7px;
    padding-top: 4px;
    text-align:center;
    border-radius: 10px;
    font-size: 16px;
  }

  @media only screen and (min-width: 1200px) {
    .about__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .portrait__item {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .portrait__item {
      margin-bottom: 2rem;
    }
    .about__container {
      flex-flow: column;
    }
  }