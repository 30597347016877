.modal{
    position:fixed;
    top:0;
    left:0;
    z-index:1055;
    display:none;
    width:100%;
    height:100%;
    overflow-x:hidden;
    overflow-y:auto;
    outline:0
}
.modal-dialog{
    position:relative;
    width:auto;
    margin:.5rem;
    pointer-events:none
}
.modal.fade .modal-dialog{
    transition:transform .3s ease-out;
    transform:translate(0,-50px)
}
@media (prefers-reduced-motion:reduce){
    .modal.fade .modal-dialog{
        transition:none
    }
}
.modal.show .modal-dialog{transform:none}
.modal.modal-static .modal-dialog{transform:scale(1.02)}
.modal-dialog-scrollable{height:calc(100% - 1rem)}
.modal-dialog-scrollable .modal-content{max-height:100%;overflow:hidden}
.modal-dialog-scrollable .modal-body{overflow-y:auto}
.modal-dialog-centered{
    display:flex;
    align-items:center;
    min-height:calc(100% - 1rem)
}
.modal-content{
    position:relative;
    display:flex;
    flex-direction:column;
    width:100%;
    pointer-events:auto;
    background-color:#fff;
    background-clip:padding-box;
    border:1px solid rgba(0,0,0,.2);
    border-radius:.5rem;
    outline:0
}
.modal-backdrop{
    position:fixed;
    top:0;left:0;
    z-index:1050;
    width:100vw;
    height:100vh;
    background-color:#000
}
.modal-backdrop.fade{opacity:0}
.modal-backdrop.show{opacity:.5}
.modal-header{
    display:flex;
    flex-shrink:0;
    align-items:center;
    justify-content:space-between;
    padding:1rem 1rem;
    border-bottom:1px solid #dee2e6;
    border-top-left-radius:calc(.3rem - 1px);
    border-top-right-radius:calc(.3rem - 1px)
}

.closeBtn{
    border-radius: 50%;
    width: 4.5vh;
    height: 4.5vh;
    border: none;
    background-color: none;
    transition: all 0.2s linear;
}
.closeBtn:hover{
    background-color: rgba(83, 83, 117, 0.486);
}
.modal-header .btn-close{
    padding:.5rem .5rem;
    margin:-.5rem -.5rem -.5rem auto;
}
.modal-title{
    font-size: 32px;
    text-align: center;
    margin-bottom:0;
    font-size: 42px;
    padding-left: 3%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.modal-body{
    position:relative;
    flex:1 1 auto;
    padding:1rem;
}
.modal-footer{
    display:flex;
    flex-wrap:wrap;
    flex-shrink:0;
    align-items:center;
    justify-content:center;
    padding:.75rem;
    border-top:1px solid #dee2e6;
    border-bottom-right-radius:calc(.3rem - 1px);
    border-bottom-left-radius:calc(.3rem - 1px)
}
.modal__pic-wrap {
    position: relative;
    overflow: hidden;
  }
.modal__img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius:.5rem;
    width: 90%;
    max-width: 100%;
    object-fit: scale-down;
    transition: all 0.2s linear;
}
.modal-footer>*{margin:.25rem}
@media (min-width:576px){
    .modal-dialog{max-width:500px;margin:1.75rem auto}
    .modal-dialog-scrollable{height:calc(100% - 3.5rem)}
    .modal-dialog-centered{min-height:calc(100% - 3.5rem)}
    .modal-sm{max-width:300px}
}
@media (min-width:992px){
    .modal-lg,.modal-xl{max-width:800px}
}
@media (min-width:1200px){
    .modal-xl{max-width:1140px}
}
.modal-fullscreen{
    width:100vw;
    max-width:none;
    height:100%;
    margin:0
}
.modal-fullscreen .modal-content{
    height:100%;
    border:0;
    border-radius:25px
}
.modal-fullscreen .modal-header{border-radius:0}
.modal-fullscreen .modal-body{overflow-y:auto}
.modal-fullscreen .modal-footer{border-radius:0}
@media (max-width:575.98px){
    .modal-fullscreen-sm-down{width:100vw;max-width:none;height:100%;margin:0}
    .modal-fullscreen-sm-down .modal-content{height:100%;border:0;border-radius:0}
    .modal-fullscreen-sm-down .modal-header{border-radius:0}
    .modal-fullscreen-sm-down .modal-body{overflow-y:auto}
    .modal-fullscreen-sm-down .modal-footer{border-radius:0}
}
@media (max-width:767.98px){
    .modal-fullscreen-md-down{width:100vw;max-width:none;height:100%;margin:0}
    .modal-fullscreen-md-down .modal-content{height:100%;border:0;border-radius:0}
    .modal-fullscreen-md-down .modal-header{border-radius:0}
    .modal-fullscreen-md-down .modal-body{overflow-y:auto}
    .modal-fullscreen-md-down .modal-footer{border-radius:0}
}
@media (max-width:991.98px){
    .modal-fullscreen-lg-down{width:100vw;max-width:none;height:100%;margin:0}
    .modal-fullscreen-lg-down .modal-content{height:100%;border:0;border-radius:0}
    .modal-fullscreen-lg-down .modal-header{border-radius:0}
    .modal-fullscreen-lg-down .modal-body{overflow-y:auto}
    .modal-fullscreen-lg-down .modal-footer{border-radius:0}
}
@media (max-width:1199.98px){
    .modal-fullscreen-xl-down{width:100vw;max-width:none;height:100%;margin:0}
    .modal-fullscreen-xl-down .modal-content{height:100%;border:0;border-radius:0}
    .modal-fullscreen-xl-down .modal-header{border-radius:0}
    .modal-fullscreen-xl-down .modal-body{overflow-y:auto}
    .modal-fullscreen-xl-down .modal-footer{border-radius:0}
}
@media (max-width:1399.98px){
    .modal-fullscreen-xxl-down{width:100vw;max-width:none;height:100%;margin:0}
    .modal-fullscreen-xxl-down .modal-content{height:100%;border:0;border-radius:0}
    .modal-fullscreen-xxl-down .modal-header{border-radius:0}
    .modal-fullscreen-xxl-down .modal-body{overflow-y:auto}
    .modal-fullscreen-xxl-down .modal-footer{border-radius:0}
}

.btn-outline-primary {
    font-size: 20px;
    border-radius: 25px;
    color: #525050;
    border-color: #525050;
    padding-left:20px;
    padding-right:20px;
    padding-bottom: 5px;
    padding-top: 5px;
    transition: all 0.2s linear;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #525050;
    border-color: #525050;
  }

  .btn-outline-secondary {
    font-size: 20px;
    border-radius: 25px;
    color: #7bbff7;
    border-color: #7bbff7;
    padding-left:20px;
    padding-right:20px;
    padding-bottom: 5px;
    padding-top: 5px;
    transition: all 0.2s linear;

  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #7bbff7;
    border-color: #7bbff7;
  }
