body,
html {
    height: 100%
}

h3 {
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 96px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

.particles-js canvas {
    display: block;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-transition: opacity .8s ease, -webkit-transform 1.4s ease;
    transition: opacity .8s ease, transform 1.4s ease
}

.particles-js {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0);
    object-fit: contain;
}

@media only screen and (max-width: 1220px) {
    h3{
        text-align: center;
        font-size: 64px;
    }
  }
